import React from 'react'
import { Helmet } from 'react-helmet'
import PrimaryButton from '../../components/Buttons/PrimaryButton'

export default function ContactPage() {
    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Barkhaus - Contact</title>
      </Helmet>
        <div className={`text-black`}>
          <div style={{ maxWidth: 1600 }} className="z-10 relative block w-full pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-24 md:pb-24 mx-auto text-left">
            <div className={`w-full md:w-6/12 mx-auto mb-10 md:mb-0 text-center content-center`}>
              <h1 className={`h2 mb-8`}>Throw us a bone.</h1>
              <p className={`p mb-8`}>Do you have a dog show you think we would be a good fit for? Sniffing out unique barketing initiatives, in-<span className="italic">fur</span>-encer programs or PR stunts?</p>
              <a
                href={`https://secure.aspca.org/donate/ps-gn-p2?ms=MP_PMK_Googlebrand&initialms=MP_PMK_Googlebrand&pcode=WPSN7GO2PK01&lpcode=WPSN7GO1PK01&test&ds_rl=1066461&gclid=Cj0KCQjw0oyYBhDGARIsAMZEuMsVjf7So6T_Q2f640Pr4UUppf2QaC_Seg_WTmeuxq02u1JEivHyFjMaAg6hEALw_wcB&gclsrc=aw.ds`}
                className={`
                primary-btn
                block md:table
                h-12 md:h-14
                rounded-full
                text-base 
                leading-5 
                px-8 py-3 md:py-4
                font-bold 
                text-center 
                border-2 
                border-black 
                
                text-white bg-black
                mx-auto`}
                target={'_blank'}
              >Throw us a bone</a>
            </div>
          </div>
        </div>
    </>
    )
}