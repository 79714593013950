import * as React from "react"
import LinkFadeDown from "../TransitionLinks/LinkFadeDown"

const PrimaryButton = ({
  text,
  url,
  invert = false,
  className = '',
  onDarkBkg = false
}) => {

  return (
    <LinkFadeDown url={url} className={`
      primary-btn
      block md:table
      h-12 md:h-14
      rounded-full
      text-base 
      leading-5 
      px-8 py-3 md:py-4
      font-bold 
      text-center 
      border-2 
      border-black 
      ${ onDarkBkg ? 'hover:border-white focus:border-white' : '' }
      ${ invert ? 'text-black bg-white' : 'text-white bg-black'}
      ${ className }`}>
      {text}
    </LinkFadeDown>
  )
}


export default PrimaryButton
